<template>
    <div class="cinematic-wrapper">
        <div id="cinematicTarget" v-show="text"></div>
        <div class="cinematic-hint" v-show="text">
            <span class="chevron bottom"></span>
        </div>
        <div v-if="!text" class="cinemtic-editor">
            <h1>Cinematic Riddle</h1>
            <div>
                <textarea v-model="newText" maxlength="160"/>
            </div>
            <div>
                <a target="newCinematic" :href="link" class="cinematic-buttom">Create</a>
            </div>
           <a class="cinematic-logo" href="https://luisa.cloud"></a>
      
        </div>
     

    </div>
  </template>  
<style lang="scss">
    .cinematic-wrapper {
        margin: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        font-size: 20px;
    }

    #cinematicTarget{
        height: 100vh;
        width: 100%;
    }


    .cinematic-content-cntr{
        width: 90%;
        min-width: 320px;
        max-width: 520px;
        height: 70%;
    }

    @media only screen and (max-width: 600px) {

        .cinematic-container{
            height: 100vh;
        }
        .cinematic-wrapper {
            align-items: start;
        }

        #cinematicTarget{
            height: 100vh;
            width: 100%;
        }

        .cinematic-content{
            min-height: 100vh;
            display: flex;
            justify-content: center;    
            align-items: flex-start;
          
        }

        .cinematic-content-cntr{
            width: 90%;
            height: 80%;
            margin-top: 7%;
        }
    }
 
   
    .cinemtic-editor {
        h1 {
            font-size: 24px;
        }
        textarea {
            width: 100%;
            height: 200px;
            border:2px solid #000;
            border-radius: 8px;
            resize: none;
            font-size: 20px;
            font-family: 'Courier New', Courier, monospace;
            padding: 8px;
            &:focus {
                border:2px solid #000;
                outline: none;
            }
        }
        .cinematic-buttom {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            border: 2px solid rgba(241, 91, 181, 1);
            color:rgba(241, 91, 181, 1);
            border-radius: 6px;
            background-color: rgba(0, 0, 0, 0);
            min-height: 26px;
            flex-basis: 41px;
            display: inline-block;
            padding-left: 14px;
            padding-right: 14px;
            padding-top: 6px;
            padding-bottom: 6px;
            text-decoration: none;

            &:hover{
                background:rgba(241, 91, 181, 1);
                color: #fff;
            }
        }
    }

    .cinematic-logo{
        position: fixed;
        top:8px;
        left: 8px;
        height: 40px;
        width: 30px;
        background: url('/logo.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border: none;
        border-radius: none;
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .cinematic-hint{
        position: fixed;
        bottom: 8px;
        left:50%;
        width: 16px;
        height: 16px;
        transform: translateX(-50%);
        text-align: center;
        animation-name: fadein;
        animation-duration: 0.5s;
        animation-delay: 3s;
        animation-fill-mode: forwards;
        opacity: 0;
    }

    .chevron::before {
        border-style: solid;
        border-width: 1px 1px 0 0;
        content: '';
        display: inline-block;
        height: 80%;
        position: relative;
        top: 0.15em;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 80%;
    }

    .chevron.right:before {
        left: 0;
        transform: rotate(45deg);
    }

    .chevron.bottom:before {
        top: 0;
        transform: rotate(135deg);
    }

    
</style>
<script>

import cinematic from './cinematic'

  export default {
    name: 'Cinematic',
    data: function () {
      return {
        text: '',
        newText: ''
      }
    },
    components: {
    },
    computed: {
        link () {
            const prefix = location.protocol + "//" + location.host + '/#/cinematic/home.html'
            const text =  window.btoa(encodeURIComponent(this.newText));
            return prefix + '?text=' + text
        }
    },
    methods: {
        show() {
            if (this.$route.query.text) {
                const text = this.$route.query.text
                this.text = decodeURIComponent(window.atob(text));
            }
            if (this.text) {
                this.$nextTick(() => {
                    console.debug(this.text)
                    cinematic('cinematicTarget', this.text)
                }) 
            }
        },
    },
    watch: {
        $route () {
            console.debug('route')
            this.show()
        }
    },
    async mounted () {
       this.show()
    }
  }
  </script>
  